import { createApp, onMounted } from 'vue'
import { toast, Toaster } from 'vue-sonner'

import VIcon from '/@front:shared/components/v-icon.vue'
import { http } from '/@front:shared/plugins/http'

const handleFlitsMessages = () => {
  const items: any[] = window._state.flits || []
  items.forEach((item) => {
    if (item.level === 'error') {
      toast.error(item.message)
    } else if (item.level === 'success') {
      toast.success(item.message)
    } else {
      toast(item.message)
    }
  })
}

function initializeHeader() {
  createApp({
    name: 'maasland-header',
    components: {
      Toaster,
      'v-icon': VIcon,
    },
    data: () => {
      return {
        showNavigation: false,
        showLanguageNavigation: false,
      }
    },
    methods: {
      toggleNavigation() {
        this.showNavigation = !this.showNavigation
        document.body.classList.toggle('noscroll', this.showNavigation)
      },

      toggleSubNav(event: MouseEvent) {
        const el = (event.target as HTMLAnchorElement).closest('li')
        if (el) {
          el.classList.toggle('subnav-active')
        }
      },

      toggleLanguageNavigation() {
        this.showLanguageNavigation = !this.showLanguageNavigation
      },

      logout(event: MouseEvent) {
        const target = event.target as HTMLAnchorElement

        http.post(target.href).then(() => {
          location.reload()
        })
      },
    },
    setup() {
      onMounted(() => {
        handleFlitsMessages()
      })
    },
  }).mount('#header-top')
}

export const initializeBase = () => {
  initializeHeader()
}
